import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import Sharee from "../images/sharee.jpg";
import Tshirt from "../images/churidar.jpg";
import Shirtmen from "../images/shirt-men.jpg";
import Tshirt2 from "../images/t-shirtmen.jpg";
import Jeanswomen from "../images/jeans-women.jpg";
import Blazarbiman from "../images/blazar.jpg";
import Panjabi from "../images/panjabi.jpg";

import Kid from "../images/kids.jpg";
import Tshirt45up from "../images/t-shirt45up.jpg";
// import Chikimage from "../images/chikimage.jpg";

import "./Products.css";

import { Link } from 'react-router-dom';


function Products() {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3
      };

  return (
    <div>
      
       
        <div className="animat">
        <Slider {...settings}>

          <div>
          <div className="card mb-4 border-White box ">
                <Link to="/saree">
                        <img src={Sharee} className="card-img-top"   alt="Saree" />
                        </Link>
                            <div className="card-body">
                                
                                <p style={{color: "maroon"}} className="card-text mb-0">Sarees</p>
                                <Link to="/saree">
                                <p className="card-text mb-0"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
    </svg></span></small></p> </Link>
                                </div>
                                </div>
        
          </div>

          <div>
          <div className="card mb-4 border-White box ">
                <Link to="/jeans">
                        <img src={Jeanswomen} className="card-img-top"   alt="Tshirt" />
                        </Link>
                            <div className="card-body">
                                
                                <p style={{color: "maroon"}} className="card-text mb-0">Jeans</p>
                                <Link to="/jeans">
                                <p className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
    </svg></span></small></p> </Link>
                                </div>
                                </div>
          </div>

          <div>
             <div className="card mb-4 border-White box ">
                <Link to="/Shirt">
                        <img src={Shirtmen} className="card-img-top"   alt="Shirtmen" />
                        </Link>
                            <div className="card-body">
                                
                                <p style={{color: "maroon"}} className="card-text mb-0">Shirt</p>
                                <Link to="/Shirt">
                                <p className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
    </svg></span></small></p> </Link>
                                </div>
                                </div>
          </div>

          <div>
          <div className="card mb-4 border-White box ">
                <Link to="/Churidar">
                        <img src={Tshirt} className="card-img-top"   alt="bimanbastralaya" />
                        </Link>
                            <div className="card-body">
                                
                                <p style={{color: "maroon"}} className="card-text mb-0"><small>Churidar</small></p>
                                <Link to="/Churidar">
                                <p className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
    </svg></span></small></p> </Link>
                                </div>
                                </div>
          </div>

          <div>
          <div className="card mb-4 border-White box ">
                <Link to="/Tshirt">
                        <img src={Tshirt2} className="card-img-top"   alt="Nacklece" />
                        </Link>
                            <div className="card-body">
                                
                                <p style={{color: "maroon"}} className="card-text mb-0"><small>T-shirt</small></p>
                                <Link to="/Tshirt">
                                <p className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
    </svg></span></small></p> </Link>
                                </div>
                                </div>
          </div>

          
          <div className="card mb-4 border-White box ">
                <Link to="/Blazer">
                        <img src={Blazarbiman} className="card-img-top"   alt="Blazarbiman" />
                        </Link>
                            <div className="card-body">
                                
                                <p style={{color: "maroon"}} className="card-text mb-0"><small>Blazer</small></p>
                                <Link to="/Blazer">
                                <p className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
    </svg></span></small></p> </Link>
                                </div>
                                </div>


                                
                                <div className="card mb-4 border-White box ">
                <Link to="/Panjabi">
                        <img src={Panjabi} className="card-img-top"   alt="Panjabi" />
                        </Link>
                            <div className="card-body">
                                
                                <p style={{color: "maroon"}} className="card-text mb-0"><small>Panjabi</small></p>
                                <Link to="/Panjabi">
                                <p className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
    </svg></span></small></p> </Link>
                                </div>
                                </div>

                                <div className="card mb-4 border-White box ">
                <Link to="/kids">
                        <img src={Kid} className="card-img-top"   alt="Kid" />
                        </Link>
                            <div className="card-body">
                                
                                <p style={{color: "maroon"}} className="card-text mb-0">Kids</p>
                                <Link to="/Kids">
                                <p className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
    </svg></span></small></p> </Link>
                                </div>
                                </div>

                                <div className="card mb-4 border-White box ">
                <Link to="/tshirt">
                        <img src={Tshirt45up} className="card-img-top"   alt="Tshirt45up" />
                        </Link>
                            <div className="card-body">
                                
                                <p style={{color: "maroon"}} className="card-text mb-0">T-Shirt  </p>
                                <Link to="/tshirt">
                                <p className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
    </svg></span></small></p> </Link>
                                </div>
                                </div>
          


        
        
        
                          

                           
                           
                            
                        
                             
                              
       
          

        </Slider>
        </div>
     
    </div>
  )
}



export default Products
