import React from 'react';
import Navbar from '../inc/Navbar';
// import AddBook from '../AddBook';
import ScrollToTop from "react-scroll-to-top";
import ReactWhatsapp from "react-whatsapp";
import "./Contact.css";

function Contact() {
    return (
     
     

<>



<Navbar/>
<br/>
<br/>
<br/>

<ScrollToTop smooth top="100" color="maroon"/>

<div className="containerse">
            <center>
                        <h1 style={{color:"maroon"}}><b>BIMAN BASTRALAYA - SINCE: 2000</b></h1><br/>
                        <h3>CONTACT US</h3>
                        </center>
                    </div>



<div className='colors'>
<br/>


<div className="album py-0">
        
        <div className="container">

            <div className="row justify-content-center">
       
            <div className="col-md-5">

            <h2 style={{color:"white"}} className="card-text"> <b>BIMAN BASTRALAYA</b></h2>
                                <h6 style={{color:"white"}} className="card-text">Showroom Address</h6>
                                <p style={{color:"white"}} className="card-text mb-1">Karimpur, College Road, Nadia</p>
                                <p style={{color:"white"}} className="card-text">Pin- 741152 WB | India</p>
                                <p style={{color:"white"}} className="card-text"><br/>Copyright © Biman Bastralaya</p>
                         
                              
                                
                                <div className="d-flex justify-content-between align-items-center">


                        
                            </div>
                               
                            </div>
                            <div className="col-md-4 mt-6" >
                        
                                <h6 style={{color:"white"}} className="card-text mb-2"> <b>CONTACT OUR SHOWROOM</b></h6>
   

<a href="tel: 9932517802"  className="btn btn- btn-light mb-2">    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z"/>
</svg> +91 9932517802*</a>

<br/>

<ReactWhatsapp number="+91 9732578120" className="btn btn-success" message="I am Interested"> Whatsapp Contact</ReactWhatsapp>
                 <div className="d-flex justify-content-between align-items-center">
                         
                            </div>
                               
                            </div>
                            <div className="col-md-3 mt-6" >
                            <h6 style={{color:"white"}} className="card-text "> <b>FOR BUSINESS</b></h6>
                            <a href="tel: +91 9732578120"  className="btn btn- btn-outline-light mb-2 ">    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z"/>
</svg> +91 9732578120*</a>
<a href="mailto:  bimanbastralaya@gmail.com" className="btn btn- btn-outline-light"> bimanbastralaya@gmail.com </a> 
                              
                           
                                
                                <div className="d-flex justify-content-between align-items-center">
                                
                            </div>
                               
                            </div>
                            </div>
                            </div>
                            </div>


                         
                 
                 </div>   
                 <div className="album py-2">
        
        <div className="container">

            <div className="row">
           
            <iframe className="iframe-fluid mb-2" src="https://www.google.com/maps/embed?pb=!4v1690479647357!6m8!1m7!1sZ6g5xmOl_2Z01-ULJYumcA!2m2!1d23.98224995602774!2d88.63313116394505!3f160.65243800200318!4f6.915038183872269!5f0.7820865974627469"  height="350"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            

                            <iframe className="iframe-fluid" height="350" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Biman%20Bastralay,%20XJJM+R7R,%20Anandapally,%20West%20Bengal%20741152%20+(Biman%20Bastralay,%20XJJM+R7R,%20Anandapally,%20West%20Bengal%20741152)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>


                            </div>
                            </div>
                            </div>
                            <br/>
           


</>
        
        );
    }
    
    export default Contact;