import React, { useState, useEffect } from 'react';
import { useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
// import PaginationOld from '../pages/PaginationOld';
import {db} from '../../firebase'
import Navbar from '../inc/Navbar';
import {Link} from "react-router-dom";

import ScrollToTop from "react-scroll-to-top";
import { Typewriter } from 'react-simple-typewriter' 
import { 
  FacebookShareButton, WhatsappShareButton, WhatsappIcon,
   FacebookIcon, TwitterShareButton,   EmailShareButton,   EmailIcon,

   LinkedinShareButton,
   LinkedinIcon,
   TwitterIcon,
}  from "react-share";



function Churidar () {
    const [ setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
      const [showPerPage, setShowPerPage] = useState(51);
      const [pagination, setPagination] =useState({
          start: 0,
          end: showPerPage,
      });
  
      const onPaginationChange = (start,  end) =>{
          setPagination({start: start, end: end});
      };
      const [searchTerm, setsearchTerm] = useState("");
      const [churidar, setChuridar] = useState([])
      const navigate = useNavigate()
  
      const churidarCollectionRef = collection(db, "churidar");
      useEffect(() => {
  
          const getChuridar = async () => {
              const data = await getDocs(churidarCollectionRef);
              setChuridar(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
          }
  
          getChuridar()
      }, [])
    return (
      
<>
<Navbar/>

<br/>
<ScrollToTop smooth top="100" color="maroon" />

<br/>
<br/>
<br/>
<br/>
<div className="album py-1">
        
            <div className="container">

                <div className="row">
<nav aria-label="breadcrumb">
  <ol className="breadcrumb">
  <li style={{color:"maroon"}} className="breadcrumb-item"><Link style={{color:"maroon"}} to="/" > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
  <path fill-rule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
</svg> Back</Link></li>
    <li style={{color:"maroon"}} className="breadcrumb-item"><Link style={{color:"maroon"}} to="/saree" href="#">Saree</Link></li>
    <li style={{color:"maroon"}} className="breadcrumb-item"><Link style={{color:"maroon"}} to="/jeans" href="#">Jeans</Link></li>
    <li style={{color:"maroon"}} className="breadcrumb-item"><Link style={{color:"maroon"}} to="/churidar" href="#">Churidar</Link></li>
    <li style={{color:"maroon"}} className="breadcrumb-item"><Link style={{color:"maroon"}} to="/tshirt" href="#">T-Shirt</Link></li>
    <li style={{color:"maroon"}} className="breadcrumb-item"><Link style={{color:"maroon"}} to="/Blazer" href="#">Blazar</Link></li>
    <li style={{color:"maroon"}} className="breadcrumb-item"><Link style={{color:"maroon"}} to="/panjabi" href="#">Panjabi</Link></li>
    <li style={{color:"maroon"}} className="breadcrumb-item"><Link style={{color:"maroon"}} to="/kids" href="#">Kids</Link></li>

    <li style={{color:"maroon"}} className="breadcrumb-item"><Link style={{color:"maroon"}} to="/tshirt" href="#">T-shirt   </Link></li>
    <li style={{color:"maroon"}} className="breadcrumb-item"><Link style={{color:"maroon"}} to="/Churidar" href="#">Churidar</Link></li>
    {/* <li style={{color:"maroon"}} className="breadcrumb-item"><Link style={{color:"maroon"}} to="#" href="#">Bedshit</Link></li> */}
    <li style={{color:"maroon"}} className="breadcrumb-item"><Link style={{color:"maroon"}} to="/contact" href="#">Contact</Link></li>
    <li className="breadcrumb-item active" aria-current="page">Churidar</li>
    
  </ol>
</nav>
</div>
</div>
</div>

<h2 className="card-text mb-2" style={{paddingTop: "1rem", margin: "auto 0", color: "Gray", textAlign: "center"}}> 
<b> Search <span style={{color: "maroon", fontWeight: "bold", marginleft: '10px'}}>
    <Typewriter 
    loop
    cursor
    cursorStyle="_"
    typeSpeed={60}
    deleteSpeed={90}
    delaySpeed={1000}
    words={[ "Churidar Color", "Churidar Name"]}
    />
</span>
</b></h2>
<div className="album py-2">
        
            <div className="container">

                <div className="row">
               
                <div className="col-md-6">
                        
                        <div className="card-body">
                        <input type="text" className="form-control" placeholder="Search" name="location" 
                        onChange ={(e) => {
                          setsearchTerm(e.target.value);
                        }}
                        />
                                <div className="d-flex justify-content-between align-items-center">
                                   
                                    
                            

                            </div>
                          
                    
                        </div>

                      
                    </div>
             
                    </div>
                  
                    </div>
                    </div>
<div className="album py-4">
        
        <div className="container">

            <div className="row">
            {churidar.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } else if (
        val.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        val.des.toLowerCase().includes(searchTerm.toLowerCase()) 
      
       
        
      ){
        return val;
      }
    }).slice(pagination.start, pagination.end).map((churidarbimanbastralaya) => { return (
            <div className="col-md-4 mt-8" >
                        <div className="card mb-4 border-White box shadow">
                        <img  className="card-img-top" src={churidarbimanbastralaya.img}  alt="churidar"/>
                            <div className="card-body">
                                
                                <h6 className="card-text"> <b>{churidarbimanbastralaya.title}</b></h6>
                        
                                <p className="card-text">{churidarbimanbastralaya.des}</p>
                                <button type="button" className="btn btn btn-dark me-1">
                                        Share  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
</button>  < FacebookShareButton url="https://www.bimanbastralaya.com/Churidar" className="me-2" quote={"Biman Bastralaya"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www.bimanbastralaya.com/Churidar" className="me-2" quote={"Biman Bastralaya"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

<  TwitterShareButton url="https://www.bimanbastralaya.com/Churidar" className="me-2" quote={"Biman Bastralaya"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</ TwitterShareButton>

<  LinkedinShareButton url="https://www.bimanbastralaya.com/Churidar" className="me-2" quote={"Biman Bastralaya"} hastag={"#WebApp"}
>
    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www.bimanbastralaya.com/Churidar" quote={"Biman Bastralaya"} hastag={"#WebApp"}
>
 <  EmailIcon size={37} round={true}/>
</ EmailShareButton>
                                <div className="d-flex justify-content-between align-items-center">
                              
                               
                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                                )})} 
                            </div>
                            {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={churidar.length}
                /> */}


                            </div>
                            </div>

</>
        
        );
    }
    
    export default Churidar;



