
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
    apiKey: "AIzaSyDSvfso-kKwFbs6f7FBZEXRXM95La5n2Bg",
    authDomain: "biman-bastralaya.firebaseapp.com",
    projectId: "biman-bastralaya",
    storageBucket: "biman-bastralaya.appspot.com",
    messagingSenderId: "694645433146",
    appId: "1:694645433146:web:31313055d004a89201e603",
    measurementId: "G-X0HXR02MQD"
 
};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;