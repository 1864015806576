import React from 'react'
import Bimanbastralaya from "../images/bimanbastralaya-head.jpg";
import Bimanbastralayashowroom from "../images/bimambastralayashowroom.jpg";
import Navbar from '../inc/Navbar';
function Aboutus() {
  return (
    <div>

        <Navbar/>
        <br/>
        <br/>
        <br/>
                      <img src={Bimanbastralaya} className="d-block w-100" alt="Bimanbastralaya"/>
                      <div className="album py-4">
        
        <div className="container">
        
            <div className="row">
            
            <div className="col-md-6 mt-8" >

                       
            <div className="radius">
                        <img src={Bimanbastralayashowroom} className="card-img-top"    alt="Bimanbastralayashowroom" />
                        </div>
                                
                               
                              
                                <div className="d-flex justify-content-between align-items-center">
                               
                        
                            </div>
                               
                            </div>
                            <br/>
                            <div className="col-md-6 mb-3" >
                            <h1 style={{color:"Darkblue"}} className="card-text"> <b>Biman Bastralaya</b></h1>
                            <h1 style={{color:"Darkblue"}} className="card-text"> <b>Since: 2000</b></h1>
                                <p style={{color:"Darkblue"}} className="card-text">Best Garments Showroom in Karimpur
</p>
                                
                                <div className="d-flex justify-content-between align-items-center">
                              
                                
                           
                            </div>
                               
                            </div>
                      
                            
                           
                           
                            
                               
                            </div>
                         
                     
                            </div>
                 
                            </div>
                            
                         <hr/> 

    </div>
  )
}

export default Aboutus
