
import React from "react";
import ReactImageMagnify from 'react-image-magnify';
import Navbar from "../inc/Navbar";

function Zoom () {

    const blazarImgageUrl ='../images/blazar.jpg';
    return (
            <>
            <Navbar/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
              <div className="album py-2">
        <div className="container">
            <div className="row">

<ReactImageMagnify {...{
    smallImage: {
        alt: 'Wristwatch by Ted Baker London',
        isFluidWidth: true,
        src: blazarImgageUrl
    },
    largeImage: {
        src: blazarImgageUrl,
        width: 1200,
        height: 900
    }
}} />
</div>
</div>
</div>
            
            </>
    );
}

export default Zoom;